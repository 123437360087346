<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-tabs>
        <md-tab id="tab-home" md-label="Marcas para filtros">
          <List class="margin-fix"/>
        </md-tab>
        <md-tab id="tab-pages" md-label="Marcas para aplicaciones">
          <Apps class="margin-fix"/>
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>
<script>
import List from '@/components/Brands/List.vue'
import Apps from '@/components/Brands/Apps.vue'
import auth from '@/mixins/auth'
export default {
  name: 'Brands',
  mixins: [auth],
  components: {
    List, Apps
  }
}
</script>
